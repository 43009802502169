




import Vue from 'vue';
import {NumericCellEditorParams} from '@/common/types/NumericCellEditorParams';

export default Vue.extend({
  name: 'NumericCellEditor',
  data() {
    return {
      value: 0,
      params: null as NumericCellEditorParams | null,
    };
  },
  computed: {
    isValid() {
      return true;
    },
  },
  mounted() {
    this.value = this.params?.value;
  },
  methods: {
    getValue() {
      if (this.params?.min != undefined && this.params.min > this.value) {
        return this.getNumericTypeValue(this.params?.min);
      } else if (this.params?.max != undefined && this.params?.max < this.value) {
        return this.getNumericTypeValue(this.params?.max);
      } else {
        return this.getNumericTypeValue(this.value);
      }
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
    getNumericTypeValue(numericValue: number) {
      if (this.params?.numericType === 'float') {
        return numericValue;
      } else {
        return Math.trunc(numericValue);
      }
    },
  },
});
