<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">An enzyme catalyzed reaction has the form:</p>

      <p class="mb-5 pl-8">
        <chemical-latex content="E + S -> E + P" />
      </p>

      <p class="mb-3">The following mechanism has been proposed for this reaction:</p>

      <p class="mb-2 pl-8">
        <chemical-latex content="E + S <=>[k_1][k_{-1}] ES (fast equilibrium)" />
      </p>
      <p class="mb-2 pl-8">
        <chemical-latex content="ES ->[k_2] EI (slow)" />
      </p>
      <p class="mb-5 pl-8">
        <chemical-latex content="EI ->[k_3] E + P (fast)" />
      </p>

      <p class="mb-n3">a) What is the molecularity of the rate determining step?</p>

      <v-radio-group v-model="inputs.molecularity" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in optionsMolecularity" :key="option.value">
          <v-radio class="d-inline-block mb-0" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-n3">b) What rate law (rate equation) would be predicted by this mechanism?</p>

      <v-radio-group v-model="inputs.rateLaw" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in optionsRateLaw" :key="option.value">
          <v-radio class="d-inline-block mb-4" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-n3">c) What is the overall reaction order for this reaction?</p>

      <v-radio-group v-model="inputs.order" :disabled="!allowEditing" class="ml-6 mb-n3">
        <div v-for="option in optionsOrder" :key="option.value">
          <v-radio class="d-inline-block mb-0" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumericCellEditor from '@/common/components/NumericCellEditor.vue';

export default {
  name: 'Question474',
  components: {
    NumericCellEditor,
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molecularity: null,
        rateLaw: null,
        order: null,
      },
      optionsRateLaw: [
        {
          expression: '$\\text{rate}=\\displaystyle{k\\ce{[ES]}}$',
          value: 'usedIntermediate',
        },
        {
          expression: '$\\text{rate}=\\text{k}\\,\\text{[E]}\\text{[S]}$',
          value: 'fullRxn',
        },
        {
          expression: '$\\text{rate}=\\displaystyle{\\frac{k_{1}\\,k_2}{k_{-1}}\\ce{[E][S]}}$',
          value: 'preEqApprox',
        },
        {
          expression: '$\\text{rate}=\\displaystyle{\\frac{k_{-1}}{k_1}\\ce{[E][S]}}$',
          value: 'justES',
        },
        {
          expression: '$\\text{rate}=\\text{k}_1\\,\\text{[E]}\\text{[S]}$',
          value: 'firstStep',
        },
        {
          expression: '$\\text{rate}=\\displaystyle{\\frac{k_{-1}\\,k_2}{k_1}\\ce{[E][S]}}$',
          value: 'flippedK1Constants',
        },
      ],
      optionsMolecularity: [
        {expression: '$\\text{Unimolecular}$', value: 'unimolecular'},
        {expression: '$\\text{Bimolecular}$', value: 'bimolecular'},
        {expression: '$\\text{Termolecular}$', value: 'termolecular'},
        {expression: '$\\text{More information needed}$', value: 'moreInfoNeededA'},
      ],
      optionsOrder: [
        {expression: '$\\text{Zeroth}$', value: 'zeroth'},
        {expression: '$\\text{First}$', value: 'first'},
        {expression: '$\\text{Second}$', value: 'second'},
        {expression: '$\\text{Third}$', value: 'third'},
        {expression: '$\\text{More information needed}$', value: 'moreInfoNeededC'},
      ],
    };
  },
};
</script>
